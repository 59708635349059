.fontInter12 {
  font-family: "Inter", sans-serif;
  font-size: 12px;
}
.titleAdmSelect {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.centeredAdmSelect {
  display: flex;
  padding: 0px 40px;
  margin: 40px 20px 10px 20px;
  align-items: center;
  justify-content: center;
}

.containerSubAdm {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #FFF;
  @media screen and (min-width: 801px) {
    padding: 20px;
    min-width: 600px;
    max-width: 600px;
    font-family: "Roboto", sans-serif;
    min-height: 400px;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  @media screen and (max-width: 800px) {

    position: absolute;
    bottom: 0;
    gap: 12px;
    flex-wrap: wrap;
    width: 100vw;
    align-items: center;
    justify-items: baseline;
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    padding: 40px 20px;
  }
}

.boxSubAdm {
    @media screen and (min-width: 801px) { 
      display: flex;
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      padding: 20px;
      border-radius: 10px;
      background-color: #FFF;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    @media screen and (max-width: 800px) {
      display: flex;
      box-sizing: border-box;
      background-color: #FFF;
      width: 160px;
      height: 100px;
      padding: 20px;
      border-radius: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

}
.boxSubAdm:hover {
  opacity: 0.8;
  box-shadow: rgba(0, 48, 116, 0.918) 0px 2px 8px 0px;
  background-color: azure;
}

.boxSubAdmSelected {
  display: flex;
  box-sizing: border-box;

  opacity: 0.8;
  background-color: azure;  
  box-shadow: rgba(0, 48, 116, 0.918) 0px 2px 8px 0px;

  padding: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media screen and (min-width: 801px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 800px) {
    width: 160px;
    height: 80px;
  }
}

.boxSubAdmNotSelected {
  display: flex;
  box-sizing: border-box;

  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  filter: grayscale(100%);
  @media screen and (min-width: 801px) {
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 800px) {
    width: 160px;
    height: 80px;
  }
}

.boxSubAdmNotSelected:hover{
  filter: grayscale(0%);
  opacity: 1;
}


.lineSubs {
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;


}

.MobilePhotoTitle {
  font-family: "Roboto", sans-serif;

  position: absolute;
  height: 105px;
  left: 0px;
  top: 299px;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;

  color: #ffffff !important;
}