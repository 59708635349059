.container-vantagens {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 0px 133px;
}
.container-vantagens-mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: 30px;
}

.box-title-vantagens {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.box-title-vantagens-mobile {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.title-vantagens {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin-top: 30px;
}
.title-vantagens-mobile {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
}

.container-element {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 340px;
  height: 238px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 20px;

  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.container-element:hover {
  background-color: #f5f5f5;
  opacity: 0.9;
}

.title-element {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #000000;
}

.content-element {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  color: #000000;
}

.icon-element {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content2 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.content-box-element {
  display: flex;
  flex: 1;
  padding: 0 45px;
}

.content-box-element-mobile {
  display: flex;
  flex: 1;
  padding: 0 30px;
}

.box-content-vantagens {
  display: flex;
  flex: 1;
  gap: 40px;
  margin-top: 30px;
}

.box-content-vantagens-mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
}
.title-box-element {
  margin-top: 8px;
}
