.footer-box {
  display: flex;
  flex: 1;
  height: 430px;
  color: #fff;
  padding: 24px 133px;
  justify-content: space-between;
  flex-direction: column;
}

.termo-link {
  text-decoration: none;

  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}

.termos-box {
  display: flex;
  gap: 40px;
}

.footer-box-mobile {
  display: flex;
  flex-direction: column;
  height: 793px;
  color: #fff;
  padding: 24px;
  justify-items: center;
}

.box-mobile {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
}
