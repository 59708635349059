.cf-content {
  display: flex;
  flex: 1;
  padding: 5px 15px;
}

.cf-content p {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.cf-subcontent {
    display: flex;
    flex: 1;
    padding: 5px 15px;
}

.cf-subcontent p {
    font-family: "Roboto", sans-serif;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.cf-content-mobile {
  display: flex;
  padding: 5px 15px;
}

.cf-content-mobile p {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}
