.circle-icon {
  display: flex;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  align-items: center;
  justify-content: center;
}

.cf-content {
  display: flex;
  flex: 1;
  padding: 5px 15px;
}

.cf-content p {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.aviso-rodape-cnp {
    display: flex;
}
  
.aviso-rodape-cnp p {
    font-family: "Roboto", sans-serif;
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
}

.cf-title {
  display: flex;
  flex: 1;
  padding: 5px 15px;
}

.cf-title-mobile {
  display: flex;
  flex: 1;
  padding: 5px 15px;
}

.cf-title p {
  font-family: "Roboto", sans-serif;
      font-display: swap;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.cf-title-mobile p {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #000000;
}

.cf-content-mobile {
  display: flex;
  padding: 5px 15px;
}

.cf-content-mobile p {
  font-family: "Roboto", sans-serif;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  color: #000000;
}
