.MuiInputBase-input[type="file"] + .MuiButtonBase-root {
  color: #fff;
  padding: 8px 16px;
  border-radius: 4px;
}

.MuiInputBase-input[type="file"] + .MuiButtonBase-root span {
  text-transform: uppercase;
  font-weight: bold;
}

.form-upload {
  background: #333;
  display: block;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  width: 350px;
}

.input-personalizado {
  cursor: pointer;
}

.imagem {
  max-width: 100%;
}

.input-file {
  display: none;
}

.gradient-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #ffffff 0%, #dfdfdf 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000066;
  border-radius: 5px;
  width: 117px;
  height: 25px;
}

.text-box {
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.text-box2 {
  text-align: left;
  font: normal normal normal 13px/18px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  background-color: #fff;
}
